import React, { useState } from "react"
import PropTypes from "prop-types"

import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"

import makeStyles from "@material-ui/core/styles/makeStyles"

import HelperThumbCard from "../cards/HelperThumbCard"
import HelperDetailCard from "../cards/HelperDetailCard"

import BaseCarousel from "./BaseCarousel"

const useStyles = makeStyles(() => ({
  description: {
    fontSize: 14,
  },
}))

const HelpersGallery = ({ helpers, onSelect }) => {
  const classes = useStyles()

  const [currentHelper, setCurrentHelper] = useState(helpers[0].uid)

  const onSelectHelper = selectedUid => {
    setCurrentHelper(selectedUid)
  }

  return (
    <>
      <Hidden mdUp implementation="css">
        <BaseCarousel
          slidesToShowXs={1}
          slidesToShowSm={2}
          slidesToShowMd={3}
          slidesToShowLg={3}
          elements={helpers}
          render={helper => (
            <HelperDetailCard
              helper={helper}
              onSelect={() => onSelect(helper.uid)}
            />
          )}
        />
      </Hidden>
      <Hidden smDown implementation="css">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div style={{ padding: "0 15px 0 0" }}>
              {helpers.map(
                helper =>
                  currentHelper === helper.uid && (
                    <HelperDetailCard
                      key={helper.uid}
                      helper={helper}
                      onSelect={() => onSelect(helper.uid)}
                    />
                  )
              )}
            </div>
          </Grid>
          <Grid item xs={8}>
            <Grid container style={{ height: "100%" }}>
              <Grid item xs={12}>
                <BaseCarousel
                  elements={helpers}
                  slidesToShow={4}
                  render={helper => (
                    <HelperThumbCard helper={helper} onSelect={onSelectHelper} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {helpers.map(
                  helper =>
                    currentHelper === helper.uid && (
                      <Typography
                        key={helper.uid}
                        className={classes.description}
                        dangerouslySetInnerHTML={{
                          __html: helper.data.description.html,
                        }}
                      />
                    )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  )
}

export default HelpersGallery

HelpersGallery.defaultProps = {
  onSelect: () => { },
}

HelpersGallery.propTypes = {
  helpers: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
}
