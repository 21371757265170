import React, { useContext, useState } from "react"
import PropTypes from "prop-types"

import * as shortid from "shortid"

import makeStyles from "@material-ui/core/styles/makeStyles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import { Carousel } from "react-responsive-carousel"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import LangContext from "../../contexts/LangContext"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/carousel.scss"

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",

      lineHeight: "26px",
    },
  },
  headline: {
    fontWeight: 600,
    whiteSpace: 'pre-line',

    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  headlineThumbWrapper: {
    position: "absolute",
    zIndex: 999,
    top: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  headlineThumb: {
    color: theme.palette.common.white,
    fontWeight: 600,
    padding: 10,
    textAlign: "center",
    fontSize: 20,
  },
  slideImage: {
    height: "60vh",
    position: "relative",
    "&:after": {
      content: "",
      width: "100%",
      height: "100%",
      background: "red",
      opacity: 0.2,
    },

    [theme.breakpoints.down("xs")]: {
      height: "45vh",
    },
  },

  slideIconImage: {
    marginTop: 15,
  },

  actionButton: {
    display: "block",
    width: "170px",
    margin: "30px auto",

    [theme.breakpoints.down("xs")]: {
      margin: "0px auto",
      fontSize: 13,
    },
  },

  sliderWrapper: {
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  headerText: {
    fontWeight: 700,
  },

  cardWrapper: {
    height: "30vh",
    position: "relative",
    paddingBottom: theme.spacing(1),

    "&:last-child": {
      paddingBottom: 0,
    },

    "&:focus": {
      outline: "none",
    },
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    overflow: "hidden",
  },
}))

const HomeCarousel = ({ slides }) => {
  const classes = useStyles()

  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const [currentPhoto, setCurrentPhoto] = useState(slides[0].uniqueId)
  const [currentSlide, setCurrentSlide] = useState(0)

  const onChange = idx => {
    setCurrentPhoto(slides[idx].uniqueId)
  }

  const onChangePhoto = slide => {
    const idx = slides.findIndex(s => s.uniqueId === slide.uniqueId)

    setCurrentSlide(idx)
  }

  const getVerticalSlides = () => {
    const currentSlideIndex = slides.findIndex(
      slide => currentPhoto === slide.uniqueId
    )

    const firstSlideIndex =
      currentSlideIndex + 1 > slides.length - 1 ? 0 : currentSlideIndex + 1
    const secondSlideIndex =
      firstSlideIndex + 1 > slides.length - 1 ? 0 : firstSlideIndex + 1

    const visibleSlides = [slides[firstSlideIndex], slides[secondSlideIndex]]

    return visibleSlides.map(slide => (
      <div
        role="button"
        className={classes.cardWrapper}
        key={slide.uniqueId}
        onClick={() => onChangePhoto(slide)}
        tabIndex="0"
        onKeyDown={() => onChangePhoto(slide)}
      >
        <GatsbyImage
          loading="eager"
          alt={slide.image.alt ?? ''}
          image={slide.image.gatsbyImageData}
          className={classes.image}
        />
        <div className={classes.headlineThumbWrapper}>
          <Typography
            component="span"
            variant="h6"
            color="inherit"
            gutterBottom
            className={classes.headlineThumb}
          >
            {slide.headline}
          </Typography>
        </div>
      </div>
    ))
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Carousel
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            selectedItem={currentSlide}
            onChange={onChange}
          >
            {slides.map(slide => (
              <div key={shortid.generate()}>
                <GatsbyImage
                  loading="eager"
                  className={classes.slideImage}
                  image={slide.image.gatsbyImageData}
                  alt={slide.image.alt ?? ""}
                />
                <div className="legend">
                  <Grid container justifyContent="center">
                    <Grid item md={8} xs={12}>
                      <Typography
                        component="span"
                        variant="h4"
                        color="inherit"
                        gutterBottom
                        className={classes.headline}
                      >
                        {slide.headline}
                      </Typography>
                      <Hidden smUp implementation="css">
                        <br />
                      </Hidden>

                      <Hidden implementation="css">
                        <Typography
                          variant="body1"
                          color="inherit"
                          dangerouslySetInnerHTML={{
                            __html: slide.description.html,
                          }}
                        />
                      </Hidden>
                      <Hidden xsDown implementation="css">
                        <br />
                        <br />
                      </Hidden>

                      {slide.url && (
                        <Button
                          variant="outlined"
                          href={slide.url}
                          component="a"
                          color="inherit"
                          className={classes.actionButton}
                        >
                          {i18n.translations.findOutMore}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            ))}
          </Carousel>
        </Grid>

        <Grid item sm={4} md={3} className={classes.sliderWrapper}>
          {getVerticalSlides()}
        </Grid>
      </Grid>
    </div>
  )
}

export default HomeCarousel

HomeCarousel.propTypes = {
  slides: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
