import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import makeStyles from "@material-ui/core/styles/makeStyles"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  media: {
    height: 330,
  },
  content: {
    background: "#aaa",
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 14,
    textAlign: "center",
    fontWeight: 400,
  },
}))

const HelperThumbCard = ({ helper, onSelect }) => {
  const classes = useStyles()

  return (
    <Card elevation={0} square className={classes.locationBox}>
      <CardActionArea onClick={() => onSelect(helper.uid)}>
        <CardMedia>
          <GatsbyImage
            className={classes.media}
            image={helper.data.profile_image.gatsbyImageData}
            alt={helper.data.profile_image.alt ?? ""}
          />
        </CardMedia>
        <CardContent className={classes.content}>
          <Typography variant="h5" component="h2" className={classes.title}>
            Vezi detalii
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default HelperThumbCard

HelperThumbCard.propTypes = {
  helper: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}
