import React from "react"
import PropTypes from "prop-types"

import ProcedureDetailCard from "../cards/ProcedureDetailCard"
import BaseCarousel from "./BaseCarousel"

// eslint-disable-next-line no-unused-vars
const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
)

const ProceduresCarousel = ({ procedures }) => (
  <BaseCarousel
    elements={procedures}
    slidesToShowXs={1}
    slidesToShowSm={2}
    slidesToShowMd={3}
    slidesToShowLg={3}
    render={procedure => <ProcedureDetailCard procedure={procedure} />}
  />
)

export default ProceduresCarousel

ProceduresCarousel.propTypes = {
  procedures: PropTypes.array.isRequired,
}
