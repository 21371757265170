import React from "react"
import PropTypes from "prop-types"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded"

const useStyles = makeStyles(theme => ({
  box: {
    position: "relative",
  },
  playIcon: {
    position: "absolute",
    fontSize: 86,
    color: "rgba(255, 255, 255, 0.3)",
    top: "calc(50% - 43px)",
    left: "calc(50% - 43px)",
  },
  media: {
    height: 95,

    [theme.breakpoints.down("sm")]: {
      height: 95,
    },
    [theme.breakpoints.down("xs")]: {
      height: 95,
    },
  },
  content: {
    padding: theme.spacing(1),

    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  title: {
    color: "#aaa",
    fontSize: 14,
    fontWeight: 400,
    textAlign: "justify",
    wordBreak: "break-all",
  },
}))

const TestimonialThumbCard = ({ testimonial, onSelect, textMaxLength }) => {
  const classes = useStyles()

  const getText = text => text.substring(0, textMaxLength)

  return (
    <Card elevation={0} square className={classes.box}>
      <CardActionArea onClick={() => onSelect(testimonial.uid)}>
        <div style={{ position: "relative" }}>
          <span className={classes.playIcon}>
            <PlayCircleOutlineRoundedIcon fontSize="inherit" />
          </span>
          <CardMedia
            className={classes.media}
            image={testimonial.data.video.thumbnail_url}
            title={testimonial.data.video.title}
          />
        </div>
        <CardContent className={classes.content}>
          <Typography variant="h5" className={classes.title}>
            {testimonial.data.description.text.length > textMaxLength
              ? `${getText(testimonial.data.title.text)}...`
              : testimonial.data.title.text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default TestimonialThumbCard

TestimonialThumbCard.defaultProps = {
  textMaxLength: 500,
}

TestimonialThumbCard.propTypes = {
  testimonial: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  textMaxLength: PropTypes.number,
}
