import React, { useState, useContext } from "react"
import PropTypes from "prop-types"

import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"

import { useStaticQuery, graphql, Link } from "gatsby"
import makeStyles from "@material-ui/core/styles/makeStyles"

import TestimonialThumbCard from "../cards/TestimonialThumbCard"
import TestimonialDetailCard from "../cards/TestimonialDetailCard"
import CustomButton from "../buttons/CustomButton"
import BaseCarousel from "./BaseCarousel"

import LangContext from "../../contexts/LangContext"

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 10,
  },
  description: {
    fontSize: 14,
    color: "#666",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  wrapperHidden: {
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  },
}))

const TestimonialsBigCarousel = ({ testimonials }) => {
  const lang = useContext(LangContext)
  const classes = useStyles()

  const [currentTestimonial, setCurrentTestimonial] = useState(
    testimonials[0].uid
  )

  const data = useStaticQuery(query)

  const testimonialsPage = data.testimonialsPages.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node)
    .pop()

  const onSelectTestimonial = selectedUid => {
    setCurrentTestimonial(selectedUid)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            {testimonials.map(
              testimonial =>
                currentTestimonial === testimonial.uid && (
                  <div key={testimonial.uid}>
                    <TestimonialDetailCard
                      testimonial={testimonial}
                      onSelect={() => onSelectTestimonial(testimonial.uid)}
                    />

                    <CustomButton
                      size="small"
                      color="primary"
                      variant="contained"
                      fullWidth
                      className={classes.button}
                      component={Link}
                      to={`/${testimonialsPage.uid}/`}
                    >
                      Vezi toate testimonialele
                    </CustomButton>
                  </div>
                )
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <BaseCarousel
                elements={testimonials}
                slidesToShowXs={2}
                slidesToShowSm={3}
                slidesToShowMd={3}
                slidesToShowLg={3}
                render={testimonial => (
                  <TestimonialThumbCard
                    testimonial={testimonial}
                    onSelect={onSelectTestimonial}
                    textMaxLength={60}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} className={classes.wrapperHidden}>
              <Hidden smDown implementation="css">
                <Grid
                  style={{ height: "100%" }}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item xs={12}>
                    {testimonials.map(
                      testimonial =>
                        currentTestimonial === testimonial.uid && (
                          <div key={testimonial.uid}>
                            <Typography className={classes.title}>
                              {testimonial.data.title.text}
                            </Typography>
                            <Typography className={classes.description}>
                              {testimonial.data.description.text}
                            </Typography>
                          </div>
                        )
                    )}
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default TestimonialsBigCarousel

const query = graphql`
  query TestimonialsBigCarouselQuery {
    testimonialsPages: allPrismicTestimonialsPage {
      edges {
        node {
          uid
          lang
        }
      }
    }
  }
`

TestimonialsBigCarousel.propTypes = {
  testimonials: PropTypes.array.isRequired,
}
