import React, { useState } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"

import { graphql } from "gatsby"

import SEO from "../components/SEO"

import HomeCarousel from "../components/carousels/HomeCarousel"
import ProceduresCarousel from "../components/carousels/ProceduresCarousel"
import TestimonialsBigCarousel from "../components/carousels/TestimonialsBigCarousel"
import HelpersGallery from "../components/carousels/HelpersGallery"
import NewsCarousel from "../components/carousels/NewsCarousel"
import SectionTitle from "../components/SectionTitle"
import GeneralContact from "../components/contacts/GeneralContact"
import ContactPopup from "../components/contacts/ContactPopup"
import LocationMapCard from "../components/cards/LocationMapCard"

const useStyles = makeStyles(theme => ({
  headline: {
    fontWeight: 600,
    fontSize: 54,
    color: theme.palette.aresBlue.main,
    textAlign: "center",

    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
    },
  },
  wrapperHidden: {
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    },
  },
  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
}))

const IndexPage = ({
  data: { homepage, proceduresPages, helpers, latestNews, contactLocations },
  pageContext: { locale },
  location,
}) => {
  const classes = useStyles()
  const [selectedHelperUid, setSelectedHelperUid] = useState("-")
  const [selectedContactLocationUid, setSelectedContactLocationUid] =
    useState("-")
  const [contactModalOpen, setContactModalOpen] = useState(false)

  const meta = {
    title: homepage.data.meta_title,
    description: homepage.data.meta_description,
    keywords: homepage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const schema = homepage.data.schema_json.text || ""
  const ogp = homepage.data.ogp_tags || ""

  const slides = homepage.data.slides.map(slide => ({
    ...slide,
    uniqueId: shortid.generate(),
  }))

  const proceduresPage = proceduresPages.edges.map(r => r.node).pop()

  const procedures = homepage.data.procedures
    .filter(({ procedure: p }) => p.document)
    .map(({ procedure: p }) => {
      let body = ""

      p.document.data.body1
        .filter(b => b.id)
        .forEach(b => {
          body = `${body} ${b.primary.content.text}`
        })

      return {
        title: p.document.data.title.text,
        body,
        slug: `/${proceduresPage.uid}/${p.document.data.categories[0].category.document.uid}/${p.document.uid}/`,
        icon: p.document.data.categories[0].category.document.data.icon,
      }
    })

  const testimonials = homepage.data.testimonials
    .filter(({ testimonial: t }) => t.document)
    .map(({ testimonial: t }) => t.document)

  const helperList = helpers.edges.map(r => r.node)

  const onSelectHelper = helperUid => {
    setSelectedHelperUid(helperUid)
    setContactModalOpen(true)
  }

  const onSelectContactLocation = contactLocationUid => {
    setSelectedContactLocationUid(contactLocationUid)
    setContactModalOpen(true)
  }

  const newsItems = latestNews.edges.map(r => r.node)

  const locations = contactLocations.edges.map(r => r.node)

  return (
    <>
      <SEO schema={schema} ogp={ogp} meta={meta} locale={locale} />
      <main>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <HomeCarousel slides={slides} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h1"
                className={classes.headline}
              >
                Clinicile de cardiologie intervențională MONZA ARES
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>
                  Cele mai căutate proceduri
                </span>
              </SectionTitle>
              <ProceduresCarousel procedures={procedures} />
            </Grid>
            <Grid item xs={12}>
              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>ARES Helper</span>
              </SectionTitle>
              <br />
              <HelpersGallery helpers={helperList} onSelect={onSelectHelper} />
            </Grid>

            <Grid item xs={12} className={classes.wrapperHidden}>
              <Hidden xsDown implementation="css">
                <div className={classes.wideContact}>
                  <GeneralContact location={location} locale={locale} />
                </div>
              </Hidden>
            </Grid>

            <Grid item xs={12}>
              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>Testimoniale</span>
              </SectionTitle>
              <br />
              <TestimonialsBigCarousel testimonials={testimonials} />
            </Grid>
            <Grid item xs={12}>
              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>Noutăți</span>
              </SectionTitle>
              <br />
              <NewsCarousel newsItems={newsItems} />
            </Grid>
            <Grid item xs={12}>
              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>Unde ne găsești</span>
              </SectionTitle>
              <br />
              <Grid container spacing={2} alignItems="center">
                {locations.map(contactLocation => (
                  <Grid item xs={12} md={4} key={contactLocation.id}>
                    <LocationMapCard
                      showLinkButton
                      location={contactLocation}
                      onSelect={onSelectContactLocation}
                      showMap={false}
                      showSchedule={false}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <ContactPopup
          locale={locale}
          location={location}
          open={contactModalOpen}
          handleClose={() => setContactModalOpen(false)}
          selectedHelperUid={selectedHelperUid}
          selectedContactLocationUid={selectedContactLocationUid}
        />
      </main>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      first_publication_date
      last_publication_date
      data {
        announcement_active
        announcement_content {
          html
        }
        announcement_title {
          text
        }
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        meta_title
        meta_description
        meta_keywords
        slides {
          description {
            html
          }
          headline
          image {
            alt
            copyright
            url
            gatsbyImageData(layout: CONSTRAINED)
          }

          url
        }
        testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                id
                uid
                data {
                  title {
                    text
                  }
                  video {
                    thumbnail_url
                    embed_url
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
        }
        procedures {
          procedure {
            document {
              ... on PrismicProcedure {
                id
                uid
                data {
                  categories {
                    category {
                      document {
                        ... on PrismicProcedureCategory {
                          id
                          uid
                          data {
                            icon {
                              alt
                              gatsbyImageData(layout: FIXED, width: 150)
                            }
                          }
                        }
                      }
                    }
                  }
                  body1 {
                    ... on PrismicProcedureDataBody1Simple {
                      id
                      primary {
                        content {
                          text
                        }
                      }
                    }
                    ... on PrismicProcedureDataBody1RepeatableImageType {
                      id
                      primary {
                        content {
                          text
                        }
                      }
                    }
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }

    proceduresPages: allPrismicProcedures(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title
          }
        }
      }
    }

    helpers: allPrismicHelper(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          uid
          data {
            name {
              text
            }
            description {
              html
            }
            ratings {
              field
              value
            }
            specialisations {
              specialisation: specialization {
                document {
                  ... on PrismicSpecialisation {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            profile_image {
              alt
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
            }
          }
        }
      }
    }

    latestNews: allPrismicNews(
      filter: { lang: { eq: $locale } }
      limit: 10
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          first_publication_date
          lang
          data {
            excerpt {
              text
            }
            title {
              html
              text
            }
          }
        }
      }
    }

    contactLocations: allPrismicContactLocation(
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          data {
            address {
              text
            }
            city {
              text
            }
            coordinates {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
    proceduresPages: PropTypes.object.isRequired,
    helpers: PropTypes.object.isRequired,
    latestNews: PropTypes.object.isRequired,
    contactLocations: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
