import React from "react"
import PropTypes from "prop-types"

import NewsItemDetailCard from "../cards/NewsItemDetailCard"
import BaseCarousel from "./BaseCarousel"

const NewsCarousel = ({ newsItems }) => (
  <BaseCarousel
    elements={newsItems}
    render={newsItem => <NewsItemDetailCard newsItem={newsItem} />}
  />
)

export default NewsCarousel

NewsCarousel.propTypes = {
  newsItems: PropTypes.array.isRequired,
}
