import React, { useState } from "react"
import PropTypes from "prop-types"
import makeStyles from "@material-ui/core/styles/makeStyles"

import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"

import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded"

const queryString = require('query-string');

const useStyles = makeStyles(theme => ({
  media: {
    cursor: "pointer",
    height: 330,

    [theme.breakpoints.down("sm")]: {
      height: 300,
    },

    [theme.breakpoints.down("xs")]: {
      height: 160,
    },
  },
  playIcon: {
    cursor: "pointer",
    position: "absolute",
    fontSize: 86,
    color: "rgba(255, 255, 255, 0.3)",
    top: "calc(50% - 43px)",
    left: "calc(50% - 43px)",
  },
  content: {
    padding: 0,
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  name: {
    color: "#666",
  },
  specialisation: {
    color: theme.palette.primary.main,
    fontSize: 20,
    marginBottom: 10,
  },

  actions: {
    flexDirection: "column",
    padding: 0,
  },

  rating: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
  },

  button: {
    marginBottom: theme.spacing(1),

    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const TestimonialDetailCard = ({ testimonial, onSelect }) => {
  const classes = useStyles()

  const [mediaLoaded, setMediaLoaded] = useState(false)

  return (
    <Card elevation={0} square className={classes.locationBox}>
      <div style={{ position: "relative" }}>
        {!mediaLoaded && (
          <>
            <div
              className={classes.playIcon}
              role="button"
              tabIndex="0"
              onClick={() => setMediaLoaded(true)}
              onKeyDown={() => setMediaLoaded(true)}
            >
              <PlayCircleOutlineRoundedIcon fontSize="inherit" />
            </div>
            <CardMedia
              className={classes.media}
              image={testimonial.data.video.thumbnail_url}
              title={testimonial.data.video.title}
            />
          </>
        )}

        {mediaLoaded && (
          <CardMedia
            component="iframe"
            className={classes.media}
            src={`https://www.youtube.com/embed/${queryString.parse(
              testimonial.data.video.embed_url.replace(
                "https://www.youtube.com/watch?",
                ""
              )
            ).v
              }?feature=oembed`}
            title={testimonial.data.video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    </Card>
  )
}

export default TestimonialDetailCard

TestimonialDetailCard.defaultProps = {
  onSelect: () => { },
}

TestimonialDetailCard.propTypes = {
  testimonial: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
}
